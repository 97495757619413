import type { StartLessonMutation } from '@graphql/generated/types'

import { Analytics } from '@/core/services/analytics/analytics'
import type {
  CheckSkipMoodCheckProps,
  ChildMoodCheckEventProps,
  ChildOpenLessonPageProps,
  CourseLessonRelatedData,
  OpenLabItemProps,
} from '@/core/services/analytics/types'
import { ChildEvent } from '@/core/services/analytics/types'

export class ChildAnalytics extends Analytics {
  private get customKey() {
    return 'custom'
  }

  private courseLessonRelatedData: CourseLessonRelatedData | undefined

  public setCourseLessonRelatedData({
    courseData,
    lessonData,
  }: Pick<StartLessonMutation['startLesson'], 'lessonData' | 'courseData'>) {
    // allow empty for custom courses
    this.courseLessonRelatedData = {
      lessonName: lessonData.lessonName ?? '',
      courseId: courseData.takenCourseId ?? this.customKey,
      courseGrade: courseData.grade?.slug ?? this.customKey,
      courseSubject: courseData.subject?.slug ?? this.customKey,
    }
  }

  public emitHomePageVisited() {
    this.emitEvent(ChildEvent.HomePageVisited)
  }

  public emitMoodCheck(props: ChildMoodCheckEventProps) {
    this.emitEvent(ChildEvent.MoodCheck, props)
  }

  public emitEditProfile() {
    this.emitEvent(ChildEvent.EditProfile)
  }

  public emitSkipMoodCheck(props: CheckSkipMoodCheckProps) {
    this.emitEvent(ChildEvent.SkipMoodCheck, props)
  }

  public emitOpenCurrentLesson() {
    this.emitEvent(ChildEvent.OpenCurrentLesson, this.courseLessonRelatedData)
  }

  public emitOpenOverdueLesson() {
    this.emitEvent(ChildEvent.OpenOverdueLesson, this.courseLessonRelatedData)
  }

  public emitOpenCompletedLesson() {
    this.emitEvent(ChildEvent.OpenCompletedLesson, this.courseLessonRelatedData)
  }

  public emitLessonVideoWatched() {
    this.emitEvent(ChildEvent.LessonVideoWatched, this.courseLessonRelatedData)
  }

  public emitLessonVideoWatchedComplete() {
    this.emitEvent(
      ChildEvent.LessonVideoWatchedComplete,
      this.courseLessonRelatedData,
    )
  }

  public emitCompleteLesson() {
    this.emitEvent(ChildEvent.CompleteLesson, this.courseLessonRelatedData)
  }

  public emitLessonPdfWatched() {
    this.emitEvent(ChildEvent.LessonPdfWatched, this.courseLessonRelatedData)
  }

  public emitLessonPdfAnnotated() {
    this.emitEvent(ChildEvent.LessonPdfAnnotated, this.courseLessonRelatedData)
  }

  public emitLessonPdfSaved() {
    this.emitEvent(ChildEvent.LessonPdfSaved, this.courseLessonRelatedData)
  }

  public emitOpenNotes() {
    this.emitEvent(ChildEvent.OpenNotes, this.courseLessonRelatedData)
  }

  public emitEditNote() {
    this.emitEvent(ChildEvent.EditNote, this.courseLessonRelatedData)
  }

  public emitDeleteNote() {
    this.emitEvent(ChildEvent.DeleteNote, this.courseLessonRelatedData)
  }

  public emitAddNote() {
    this.emitEvent(ChildEvent.AddNote, this.courseLessonRelatedData)
  }

  public emitStartQuiz() {
    this.emitEvent(ChildEvent.StartQuiz, this.courseLessonRelatedData)
  }

  public emitOpenLesson(props: ChildOpenLessonPageProps) {
    this.emitEvent(ChildEvent.OpenLessonPage, props)
  }

  public emitOpenLabItem(props: OpenLabItemProps) {
    this.emitEvent(ChildEvent.OpenLabItem, props)
  }
}

export const childAnalytics = new ChildAnalytics()
