import type { ApexOptions } from 'apexcharts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { ReportsLocationOutput } from '@/graphql/generated/types'
import { useLocationReportDataQuery } from '@/graphql/generated/types'
import { theme } from '@/styles/theme'

import type { DateQuery } from '../Overview'

const useLocationChart = (date: DateQuery) => {
  const { data, loading } = useLocationReportDataQuery({
    variables: {
      endDate: date?.endDate,
      startDate: date?.startDate,
    },
  })

  const { t } = useTranslation('admin', { keyPrefix: 'location' })
  const renderGraph: ApexOptions = useMemo(() => {
    if (data) {
      const graph = data.locationReportData

      return {
        title: {
          text: t('graphs.registrations'),
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontWeight: theme.fontWeight.medium,
            fontFamily: 'Fredoka',
            color: theme.color.slateGray,
          },
        },
        colors: [
          '#28a74a',
          '#4581c3',
          '#8F1E21',
          '#DB5A5E',
          '#FFC300',
          '#99ccff',
          '#FF5733',
        ],
        series: [
          {
            data: graph.map((data: ReportsLocationOutput) => ({
              x: data.location || 'Not yet selected',
              y: data.value,
            })),
          },
        ],
        chart: {
          type: 'bar',
          height: 400,
          stacked: false,

          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No educator have completed registration in this month as to date',
          align: 'center',
          verticalAlign: 'middle',
        },
      }
    }

    return {}
  }, [data])

  return {
    renderGraph,
    loading,
  }
}

export default useLocationChart
