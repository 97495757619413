import styled, { css } from 'styled-components'

import { breakpoints } from '@/styles/media'
import type { Color } from '@/styles/theme'
import {
  changeOpacity,
  fadeInAnimation,
  flexCenter,
  flexColumn,
  oneLineText,
  typography,
} from '@/styles/utils'

export const headerHeight = '150px'
export const parentHeaderHeight = '80px'
export const parentHeaderHeightMobile = '70px'
export const containerMaxWidth = breakpoints.largeDesktop
export const titleMargin = '1rem'
export const titleHeight = '2.813rem'
export const sectionContainerPadding = '2rem'
export const contentContainerGap = '1.8rem'

export const Main = styled.main`
  width: 100%;
  height: 100%;
  padding-top: 150px;
  ${flexColumn()};
  align-items: center;
`

export const MaxWidthContainer = styled.div`
  ${flexColumn()};
  flex: 1;
  width: 100%;
  max-width: ${containerMaxWidth};
`

export const BigErrorMessage = styled.span`
  ${typography.h1()};
  color: ${({ theme }) => theme.color.white};
`

export const PageTitle = styled.h1`
  ${typography.h1()};
  color: ${({ theme }) => theme.color.white};
  margin: ${titleMargin} 0;
  height: ${titleHeight};
  ${fadeInAnimation()};
  ${oneLineText()};
`

export const SectionContainer = styled.section`
  padding: ${sectionContainerPadding};
  border-radius: 18px;
  background-color: ${({ theme }) => changeOpacity(theme.color.mirage, 0.5)};
`

export const Popup = styled.div`
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 35px;
  border-radius: 8px;
  width: 12.375rem;
  z-index: 10;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 2px 4px 10px
    ${({ theme }) => changeOpacity(theme.color.black, 0.15)};
`

export const Plus = styled.span`
  ${flexCenter};
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ theme }) => theme.color.fuchsiaBlue};
  line-height: 99%;
`

export const checkmarkStick = (color: Color = 'white') => css`
  position: absolute;
  content: '';
  display: block;
  width: 1.5px;
  transform-origin: bottom center;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color[color]};
`
