import type { Route } from '@/pages/routes'
import { replaceParams } from '@/pages/routes'

export enum ParentRoute {
  Default = '/',
  Marketplace = '/marketplace',
  Profile = '/profile',
  AddChild = '/add-child',
  lesson = '/lesson/:id',
  CalendarSchedule = '/planner',
  Resources = '/resources',
  Analytics = '/analytics',
  AnalyticsDetails = '/analytics/:childId',
  TranscriptDetails = '/transcript/:childId',
  Tour = '/tour/:mode',
  Notifications = '/notifications',
  Cancel = '/cancel',
  LiveClasses = '/live-classes',
  Lab = '/lab',
}

export const routeKey = '/parent'

const buildPath = (route: string) => `${routeKey}${route}`

export const parentRoute: Route<typeof ParentRoute> = {
  default: () => buildPath(ParentRoute.Default),
  marketplace: () => buildPath(ParentRoute.Marketplace),
  profile: () => buildPath(ParentRoute.Profile),
  addChild: () => buildPath(ParentRoute.AddChild),
  lesson: (id) => buildPath(replaceParams(ParentRoute.lesson, { id })),
  calendarSchedule: () => buildPath(ParentRoute.CalendarSchedule),
  resources: () => buildPath(ParentRoute.Resources),
  analytics: () => buildPath(ParentRoute.Analytics),
  analyticsDetails: (childId: string) =>
    buildPath(replaceParams(ParentRoute.AnalyticsDetails, { childId })),
  transcriptDetails: (childId: string) =>
    buildPath(replaceParams(ParentRoute.TranscriptDetails, { childId })),
  tour: (mode: string) => buildPath(replaceParams(ParentRoute.Tour, { mode })),
  notifications: () => buildPath(ParentRoute.Notifications),
  cancel: () => buildPath(ParentRoute.Cancel),
  liveClasses: () => buildPath(ParentRoute.LiveClasses),
  lab: () => buildPath(ParentRoute.Lab),
}
