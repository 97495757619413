import { addYears, startOfMonth } from 'date-fns'
import { useState } from 'react'
import DatePicker from 'react-datepicker'

import { Button } from '@/components'

import type { DateQuery } from '../../overview'
import {
  DateColumn,
  DatePickerContainer,
  DateRangeContainer,
} from './DateRangePicker.styled'

export function DateRangePicker({
  onChange,
}: {
  onChange: (data: DateQuery) => void
}) {
  const [dateRange, setDateRange] = useState<Date[]>([
    startOfMonth(new Date()),
    new Date(),
  ])
  const [startDate, endDate] = dateRange

  return (
    <DateColumn>
      <DateRangeContainer>
        <DateColumn>
          <DatePickerContainer>
            <DatePicker
              inline
              showMonthYearDropdown
              fixedHeight
              selectsRange
              minDate={addYears(new Date(), -3)}
              maxDate={new Date()}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                // @ts-expect-error Argument of type '[Date | null, Date | null]' is not assignable to parameter of type 'SetStateAction<Date[]>'
                setDateRange(update)
              }}
            />
          </DatePickerContainer>
        </DateColumn>
        <Button
          thin
          onClick={() => {
            onChange({
              endDate: endDate.toDateString(),
              startDate: startDate.toDateString(),
            })
          }}
        >
          Apply Dates
        </Button>
      </DateRangeContainer>
    </DateColumn>
  )
}
