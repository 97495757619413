import styled, { css, keyframes } from 'styled-components'

import { Accordion } from '@/components'
import { Expander } from '@/components/Accordion/Accordion.styled'
import { Main as StyledMain } from '@/styles/components'
import { media } from '@/styles/media'
import {
  changeOpacity,
  fadeInAnimation,
  flexColumn,
  flexRow,
  multilineTruncateText,
  transitions,
  typography,
} from '@/styles/utils'

const stickyIn = keyframes`
  0% {
    position: static;
    opacity: 1;
  }
  
  49% {
    position: static;
    opacity: 0;
  }
  
  50% {
    position: absolute;
    opacity: 0;
  }
  
  100% {
    position: absolute;
    top: 100px;
    opacity: 1;
  }
`

const stickyOut = keyframes`
  0% {
    position: absolute;
    top: 100px;
    opacity: 1;
  }

  49% {
    position: absolute;
    top: 100px;
    opacity: 0;
  }

  50% {
    position: static;
    opacity: 0;
  }

  100% {
    opacity: 1;
    position: static;
  }
`

export const Main = styled(StyledMain)`
  padding-top: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.blueChalk};
  align-items: center;
`

export const InnerContainer = styled.div`
  padding: 1rem 2rem;
  max-width: 70rem;
  width: 100%;

  ${media.mobile(
    () => css`
      padding: 0.5rem 1rem;
    `,
  )}
`

export const TopRow = styled.div<{ isAbsolute: boolean }>`
  ${flexRow()}
  justify-content: flex-start;
  margin-bottom: 1rem;
  ${fadeInAnimation()};
  ${({ isAbsolute }) => css`
    gap: ${isAbsolute ? '3rem' : '2rem'};
  `};

  ${media.mobile(
    () => css`
      flex-direction: column;
      gap: 1rem;
    `,
  )}
`

export const Left = styled.div`
  flex-basis: 12.5rem;
`

export const VideoContainer = styled.div`
  padding: 1rem 0rem;
`

export const IFrame = styled.iframe`
  border-radius: 5px;
  width: 100%;
  min-height: 20rem;

  ${media.mobile(
    () => css`
      min-height: 15rem;
    `,
  )}
`

export const UnitSec = styled.div`
  width: 100%;
  max-width: 40rem;
`

export const Unit = styled(Accordion)`
  padding: 0;
  color: ${({ theme }) => theme.color.black};
  ${typography.bodyBold()};
  gap: 0;
  max-width: 40rem;

  ${Expander} {
    &:before,
    &:after {
      background-color: ${({ theme }) => theme.color.black};
    }
  }
`

export const Lessons = styled.ul`
  ${flexColumn()};
  ${typography.body()};
  gap: 0.25rem;
  padding: 0.5rem 1rem 1rem 1rem;
  max-height: 20rem;
  overflow: scroll;
  overflow-x: hidden;
`

export const Content = styled.div`
  font-size: initial;

  a {
    color: inherit;
  }
`

export const Course = styled.div<{ isAbsolute: boolean }>`
  ${flexColumn()};
  padding: 0.5rem;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 2px 4px 10px
    ${({ theme }) => changeOpacity(theme.color.black, 0.15)};
  gap: 1rem;
  ${transitions({})('transform', 'opacity')};
  &:hover {
    transform: scale(1.03);
  }

  max-width: 13.5rem;
  width: 13.5rem;

  position: unset;

  ${({ isAbsolute }) => css`
    ${media.largeDesktop(
      () => css`
        animation: ${isAbsolute ? stickyIn : stickyOut} 250ms linear 1 forwards;
      `,
    )}

    ${media.desktop(
      () => css`
        animation: ${isAbsolute ? stickyIn : stickyOut} 250ms linear 1 forwards;
      `,
    )}
      
    ${media.tablet(
      () => css`
        position: unset !important;
      `,
    )}
  `};
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 18px;
  overflow: hidden;
`

export const CourseName = styled.p`
  height: 2.5rem;
  ${typography.bodyBold()};
  font-weight: ${({ theme }) => theme.fontWeight.big};
  font-size: ${({ theme }) => theme.fontSize.great};
  text-align: center;
  margin-bottom: 1rem;
`

export const Name = styled.p`
  height: 2.5rem;
  ${typography.bodyBold()};
  ${multilineTruncateText()};
`

export const TitleUnitsAndLessons = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.big};
  font-size: ${({ theme }) => theme.fontSize.big};
  border-radius: 10px;
  margin: 0.5rem 0rem 0.5rem 0rem;
  width: 100%;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.violetMagican};
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
`
