import type { ComponentType, PropsWithChildren, ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { Loader } from '@/components'
import type { Size } from '@/styles/theme'

import { Body, Container, Expander, Header } from './Accordion.styled'

export type WithAccordionOnToggle = {
  onAccordionToggle?: VoidFunction
}

type AccordionProps = {
  header?: NonNullable<ReactNode>
  maxHeight?: Size
  defaultExpanded?: boolean
  isExpandable?: boolean
  isLoading?: boolean
  onToggle?: VoidFunction
  onExpand?: VoidFunction
  className?: string
  disableAnimation?: boolean
  headerComponent?: ComponentType<{ isExpanded: boolean }>
}

export function Accordion({
  header,
  children,
  defaultExpanded = true,
  isLoading = false,
  disableAnimation = false,
  onExpand,
  maxHeight,
  isExpandable = true,
  onToggle: onToggleHandler,
  className,
  headerComponent: HeaderComponent,
}: PropsWithChildren<AccordionProps>) {
  const [isExpanded, setIsExpanded] = useState(false)

  const onToggle = () => {
    if (isLoading) {
      return
    }

    setIsExpanded((prevState) => !prevState)
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    setIsExpanded(defaultExpanded)

    if (defaultExpanded) {
      onExpand?.()
    }
  }, [defaultExpanded, isLoading, onExpand, isExpandable])

  const onClick = () => {
    if (!isExpanded) {
      onExpand?.()
    }

    onToggle()
    onToggleHandler?.()
  }

  return (
    <Container
      className={className}
      isLoading={isLoading}
      isExpanded={isExpanded}
      disableAnimation={disableAnimation}
      onClick={onClick}
    >
      {!HeaderComponent ? (
        <Header>
          {header}
          {isLoading ? <Loader /> : <Expander isExpanded={isExpanded} />}
        </Header>
      ) : (
        <HeaderComponent isExpanded={isExpanded} />
      )}
      {!isLoading && (
        <Body
          maxHeight={maxHeight}
          disableAnimation={disableAnimation}
          isExpanded={isExpanded}
        >
          {children}
        </Body>
      )}
    </Container>
  )
}
