import styled from 'styled-components'

import { flexRow } from '@/styles/utils'

import { ChildSubscriptions } from '../child/components/ChildSubscriptions'
import { DateRangePicker } from '../components'
import useDateQuery from '../hooks/useDateQuery'
import ParentPayingChart from '../parent/components/ParentPayingChart'
import { LocationData } from './LocationData'
import { MetricData } from './MetricData'
import { UserStatistics } from './UserCountryStatistics'
import { UserSourceData } from './UserSourceData/UserSourceData'

const Main = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 1.2em;
  width: 100%;
  height: 100vh;
`

const FlexRow = styled.div`
  ${flexRow()}
  column-gap: 1.2em;
`

export type DateQuery = {
  startDate: string
  endDate: string
}

export function Overview() {
  const { date, setDate } = useDateQuery()

  return (
    <Main>
      <FlexRow>
        <DateRangePicker onChange={setDate} />

        <MetricData date={date} />
      </FlexRow>
      <UserSourceData date={date} />
      <LocationData date={date} />
      <UserStatistics dateFilter={date} />
      <ChildSubscriptions date={date} />
      <ParentPayingChart date={date} />
    </Main>
  )
}
