import type { ApexOptions } from 'apexcharts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserSourceByDateQuery } from '@/graphql/generated/types'
import { theme } from '@/styles/theme'

import type { DateQuery } from '../Overview'

const useSourcePieChart = (date: DateQuery) => {
  const { data, loading } = useUserSourceByDateQuery({
    variables: {
      startDate: date?.startDate,
      endDate: date?.endDate,
    },
  })
  const { t } = useTranslation('admin', { keyPrefix: 'userSource' })
  const renderGraph: ApexOptions = useMemo(() => {
    if (data) {
      const graph = data?.userSourceByDate

      return {
        title: {
          text: t('graphs.title'),
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontWeight: theme.fontWeight.medium,
            fontFamily: 'Fredoka',
            color: theme.color.slateGray,
          },
        },
        colors: [
          '#28a74a',
          '#9333ea',
          '#4581c3',
          '#fcba03',
          graph?.OtherSources?.userCount ? '#99ccff' : '',
        ],
        series: [
          graph?.WordofMouth?.userCount ?? 0,
          graph?.Google?.userCount ?? 0,
          graph?.Facebook?.userCount ?? 0,
          graph?.FacebookGroupRecommendation?.userCount ?? 0,
          graph?.OtherSources?.userCount ?? 0,
        ],
        chart: {
          type: 'donut',
          height: '380',
          redrawOnWindowResize: true,
          toolbar: {
            show: true,
          },
        },
        labels: [
          'Word of Mouth',
          'Google',
          'Facebook',
          'Facebook Group Recommendation',
          graph?.OtherSources?.userCount ? 'Other Sources' : '',
        ],
        legend: {
          show: true,
          position: 'bottom',
        },
        fill: {
          opacity: 1,
        },
        noData: {
          text: 'Cureently no data',
          align: 'center',
          verticalAlign: 'middle',
        },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: '30%',
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                },
              },
            },
          },
        },
        dataLabels: {
          formatter(val, opts): any {
            return opts.w.config.series[opts.seriesIndex]
          },
        },
      }
    }

    return {}
  }, [data])

  return {
    loading,
    renderGraph,
    data: data?.userSourceByDate,
  }
}

export default useSourcePieChart
