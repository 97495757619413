import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import downloadImg from '@/assets/icons/download-white.svg'
import { Button, Loader } from '@/components'
import { media } from '@/styles/media'

import { LocationContainer } from '../LocationData/LocationData.styled'
import { FlexRow } from '../MetricData/MetricData.styled'
import type { DateQuery } from '../Overview'
import useSourcePieChart from './useSourcePieChart'

export function UserSourceData({ date }: { date: DateQuery }) {
  const { loading, renderGraph, data } = useSourcePieChart(date)
  const { t } = useTranslation('admin')

  function downloadCSV(array: any[], filename: string) {
    const csv = convertArrayOfObjectsToCSV(array)
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function convertArrayOfObjectsToCSV(array: any[]) {
    const header = Object.keys(array[0]).join(',')
    const csv = array.map((obj) => Object.values(obj).join(',')).join('\n')
    return `${header}\n${csv}`
  }

  const WidthContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: auto;
    justify-content: space-between;
    flex: 1;
    width: 50%;
    ${media.mobile(
      () => css`
        flex-direction: column;
      `,
    )}
  `

  const DownloadButton = styled(Button)`
    gap: 0.5rem;
  `

  const DownloadIcon = styled.img``

  return (
    <LocationContainer>
      {loading ? (
        <Loader size="4em" color="codeCurrent" />
      ) : (
        <>
          <Chart
            series={renderGraph.series}
            type="donut"
            options={renderGraph}
            height={renderGraph.chart?.height}
          />
          <WidthContainer>
            <FlexRow>
              {data?.Facebook && (
                <DownloadButton
                  thin
                  disabled={data.Facebook.users?.length === 0}
                  onClick={() => {
                    downloadCSV(
                      data.Facebook?.users ? data.Facebook.users : [],
                      'facebook-source-users.csv',
                    )
                  }}
                >
                  <DownloadIcon src={downloadImg} />
                  {t('userSource.lbFacebook')}
                </DownloadButton>
              )}

              {data?.FacebookGroupRecommendation && (
                <DownloadButton
                  thin
                  disabled={
                    data.FacebookGroupRecommendation.users?.length === 0
                  }
                  onClick={() => {
                    downloadCSV(
                      data.FacebookGroupRecommendation?.users
                        ? data.FacebookGroupRecommendation.users
                        : [],
                      'FacebookGroupRecommendation-source-users.csv',
                    )
                  }}
                >
                  <DownloadIcon src={downloadImg} />
                  {t('userSource.lbFacebookGroupRecommendation')}
                </DownloadButton>
              )}

              {data?.Google && (
                <DownloadButton
                  thin
                  disabled={data.Google.users?.length === 0}
                  onClick={() => {
                    downloadCSV(
                      data.Google?.users ? data.Google.users : [],
                      'google-source-users.csv',
                    )
                  }}
                >
                  <DownloadIcon src={downloadImg} />
                  {t('userSource.lbGoogle')}
                </DownloadButton>
              )}

              {data?.WordofMouth && (
                <DownloadButton
                  thin
                  disabled={data.WordofMouth.users?.length === 0}
                  onClick={() => {
                    downloadCSV(
                      data.WordofMouth?.users ? data.WordofMouth.users : [],
                      'wordofmouth-source-users.csv',
                    )
                  }}
                >
                  <DownloadIcon src={downloadImg} />
                  {t('userSource.lbWordofmouth')}
                </DownloadButton>
              )}

              {data?.OtherSources &&
              data?.OtherSources.userCount &&
              data?.OtherSources.userCount > 0 ? (
                <DownloadButton
                  thin
                  onClick={() => {
                    downloadCSV(
                      data.OtherSources?.users ? data.OtherSources.users : [],
                      'othersources-source-users.csv',
                    )
                  }}
                >
                  <DownloadIcon src={downloadImg} />
                  {t('userSource.lbOtherSource')}
                </DownloadButton>
              ) : null}
            </FlexRow>
          </WidthContainer>
        </>
      )}
    </LocationContainer>
  )
}
