import styled from 'styled-components'

import { Button } from '@/components'
import { flexRow } from '@/styles/utils'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 1rem;
`
export const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  gap: 1rem;
  align-items: end;
`

export const FlexRow = styled.div`
  ${flexRow()}
  gap:1em;
  flex-wrap: wrap;
  align-items: center;
`
export const DownloadIcon = styled.img``

export const DownloadButton = styled(Button)`
  gap: 0.5rem;
  width: fit-content;
  height: 46px;
  margin-bottom: 1.375rem;
`
