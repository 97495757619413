import type { ApexOptions } from 'apexcharts'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCountryReportDataQuery } from '@/graphql/generated/types'
import { theme } from '@/styles/theme'

import type { DateQuery } from '../overview'

export const useCityStateChart = (date: DateQuery) => {
  const [filterQuery, setFilterQuery] = useState<{
    country: string
    location: string
  }>({
    country: 'Canada',
    location: 'state',
  })

  const { data, loading } = useCountryReportDataQuery({
    fetchPolicy: 'no-cache',
    variables: { ...filterQuery, ...date },
  })

  const { t } = useTranslation('admin', { keyPrefix: 'location' })

  const graph: ApexOptions = useMemo(() => {
    if (data) {
      const graph = data.countryReportData?.locationData
      return {
        title: {
          text: t('graphs.statesBased', { country: filterQuery.country }),
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontWeight: theme.fontWeight.medium,
            fontFamily: 'Fredoka',
            color: theme.color.slateGray,
          },
        },
        colors: [
          '#28a74a',
          '#4581c3',
          '#8F1E21',
          '#DB5A5E',
          '#FFC300',
          '#99ccff',
          '#FF5733',
        ],
        series: [
          {
            data: graph.map(
              (data: {
                __typename?: 'LocationData'
                location: string
                value: number
              }) => ({
                x: data.location,
                y: data.value,
              }),
            ),
          },
        ],
        chart: {
          type: 'bar',
          height: 'auto',
          redrawOnWindowResize: true,
          stacked: false,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 0,
            distributed: true,
            barHeight: '60',
          },
        },
        legend: {
          show: false,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No end user for this country in this month as to date',
          align: 'center',
          verticalAlign: 'middle',
        },
      }
    }

    return {}
  }, [data])

  return {
    loading,
    filterQuery,
    setFilterQuery,
    graph,
    data: data?.countryReportData?.users,
  }
}
